.root {
  @apply sticky top-0 z-40 flex flex-col w-full items-start justify-end bg-white transition-all duration-150 py-2 sm:px-8 px-4;
  border: 1px solid #d9d9d9;
}

.rootNone {
  display: none;
}

.nav {
  @apply flex flex-row w-full justify-between items-center;
  min-height: 62px;
}

.searchbarWrapper {
  @apply flex-col w-full hidden lg:flex lg:max-w-lg  xl:max-w-screen-md;
}

.link {
  @apply inline-flex items-center leading-6 transition ease-in-out duration-75 cursor-pointer;
  color: #666666;
}

.link:hover {
  @apply text-black;
}

.link:focus {
  @apply outline-none;
  color: #111111;
}

.logoLink {
  @apply opacity-100 justify-center cursor-pointer transition-opacity ease-in-out delay-150 hover:opacity-90 md:mx-0 mx-auto pr-3 flex items-center flex-shrink-0;
}

.logo {
  @apply !max-w-[128px] sm:!max-w-[180px] object-contain !max-h-[63px];
}

.menuWrap {
  @apply flex items-center  flex-1;
  @media (min-width: 1024px) {
    min-width: 247px;
  }
}

.item {
  @apply md:mr-5 mr-4 cursor-pointer relative transition ease-in-out duration-100 flex items-center outline-none text-black;

  &:last-child {
    @apply mr-0;
  }

  &:focus,
  &:active {
    @apply outline-none;
  }
}

