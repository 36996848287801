@screen lg {
  .dropdownMenu {
    @apply absolute top-10 border border-neutral-50 shadow-lg w-56 h-auto;
  }
}

.dropdownMenu {
  @apply left-4 mt-14 origin-top-left outline-none bg-white;
}
.dropdownMenuChild {
  @apply absolute top-0 border border-neutral-50 shadow-lg w-60;
  @apply left-56 mt-0 origin-top-left outline-none bg-white;
  height: 50vh;
  overflow: auto;
  transform: translateX(-2px);
}

.link {
  @apply text-black flex cursor-pointer px-6 py-3 transition ease-in-out duration-150 leading-6 font-medium items-center;
  text-transform: capitalize;
}

.link:hover {
  @apply bg-neutral-50;
}

.link.active {
  @apply font-bold;
  background-color: #eaeaea;
}

.off {
  @apply hidden;
}

.navIconHome {
  width: 21px !important;
  height: 13px !important;

  
}

.navIcon span {
  display: block;
  position: absolute;
  height: 2px !important;
  width: 100%;
  background: #000;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.navIconHome span:nth-child(2),
.navIconHome span:nth-child(3) {
  top: 4.5px !important;
}

.navIconHome span:nth-child(4) {
  top:10px !important;
}

.navIconHome.open span:nth-child(1) {
  top: 4.5px !important;
  width: 0% !important;
  left: 50% !important;
}

.navIcon {
  width: 40px;
  height: 22px;
  position: relative;
  margin: 0 15px -5px 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.navIcon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #000;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.navIcon span:nth-child(1) {
  top: 0px;
}

.navIcon span:nth-child(2),
.navIcon span:nth-child(3) {
  top: 9px;
}

.navIcon span:nth-child(4) {
  top: 18px;
}

.navIcon.open span:nth-child(1) {
  top: 9px;
  width: 0%;
  left: 50%;
}

.navIcon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.navIcon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navIcon.open span:nth-child(4) {
  top: 9px;
  width: 0%;
  left: 50%;
}

.dropdownMenuItem > ul.dropdownMenuChild {
  display: none;
}

.dropdownMenuItem:hover > ul.dropdownMenuChild {
  display: block;
}
.dropdownMenuItem {
  position: relative;
}
.menuIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: rotate(-90deg) translateX(25%);
}
