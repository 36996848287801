.root {
  @apply hidden sm:flex justify-between items-center w-full text-sm leading-6 font-bold lg:px-8 px-3 py-2 bg-topPanelBg;
  color: #ffffff;
}

.linksWrapper {
  @apply inline-flex flex-row items-center justify-start flex-wrap;
}

.linkItem {
  @apply inline-flex justify-start items-center lg:mr-10 mr-3 md:text-sm text-xs;
  color: #ffffff;
}

.greyLinkItem {
  @apply inline-flex justify-start items-center lg:mr-10 mr-3 md:text-sm text-xs;
  color: var(--textGray);
}

.greyLinkItem {
  fill: var(--textGray);
}

.linkItemIconPhone {
  @apply block mr-2.5;
  width: 20px;
  height: 20px;
  fill: var(--headerIcon);
}

.linkItemIconEmail {
  @apply block mr-2.5;
  width: 20px;
  height: 20px;
  fill: var(--headerIcon);
}

.rateWrapper {
  height: 20px;
  overflow: hidden;
}
