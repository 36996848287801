.root {
  @apply relative text-sm bg-white w-full transition-colors duration-150 border;
}

.searchBox {
  @apply flex flex-row max-w-[570px] h-[46px] w-full mx-auto;
}

.input {
  @apply bg-groupedInput pl-6 pr-20 py-2 appearance-none w-full transition duration-150 rounded-l-brand ease-in-out;
  padding: 10px 18px;
  color: #000;
  border: 1px solid #cccccc;
}

.input::placeholder {
  color: black;
  font-size: 1rem;
  opacity: 0.6;
}

.input:focus {
  @apply outline-none border-brand;
  box-shadow: inset 0 0 0 1px var(--brand);
}

.iconContainer {
  @apply absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none;
}

.icon {
  @apply h-5 w-5;
}

@screen sm {
  .input {
    min-width: 300px;
  }
}

.btn {
  @apply !rounded-l-none w-full px-0 max-w-[54px] md:max-w-max md:px-8;
}

.btn svg {
  @apply md:mr-3;
  min-width: 30px;
  min-height: 30px;
}
